import Vue from 'vue';
import Vuex from 'vuex';
import FavouritesModule from './modules/FavouritesModule';
import themeService from '../services/themeService';
import magazineService from '@/services/magazineService';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    darkMode: false,
    test: '',
    magazineToken: null,
    isAndroid: null,
    isElectron: false,
  },
  mutations: {
    SET_DARK_MODE(state, value){
      state.darkMode = value;
      themeService.setDarkTheme(value);
    },
    SET_TEST(state, value) {
      state.test = value;
    },
    SET_MAGAZINE_TOKEN(state, value) {
      state.magazineToken = value;
    },
    SET_IS_ANDROID(state, value) {
      state.isAndroid = value;
    },
    SET_IS_ELECTRON(state, value) {
      state.isElectron = value;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    sendLog({state}, log) {
      // console.log('log', log);
      if(state.isElectron) {
        magazineService.sendStatElectron(log.meta || {}, log.description);
      } else if (state.isAndroid) {
        magazineService.sendStat(log.meta || {}, log.description);
      }
    },
    // eslint-disable-next-line no-unused-vars
    setLocalUserToken({ commit }, token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    setIsAndroid({commit}) {
      const ua = navigator.userAgent.toLowerCase();
      commit('SET_IS_ANDROID', ua.indexOf("android") > -1);
    },
    async filterOutMagazines({state}, mags) { // todo eventually link this to an mdm block list
      return state.isElectron ? magazineService.filterOutMagazines(mags) : mags;
    }
  },
  modules: {
    favourites: FavouritesModule
  }
});

export default store;
