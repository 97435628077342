<style lang="scss">
    .favourites-section{
        &__title{
            font-size: 20pt;
        }

        .no-favourites{
            font-size: 18pt;
        }
    }
</style>

<template>
    <section class="favourites-section pb-4 animate__animated animate__fadeIn animate__slow theme-purple-background">
        <p class="favourites-section__title white--text font-weight-bold px-6 py-4">Favourite Magazines</p>
        <div class="swiper-container swiper-container-fav" v-if="favourites.length > 0">
            <div class="swiper-wrapper">
                <div @click="emitFavourite(favourite, $event)" v-for="favourite in favourites" :key="favourite.name"
                     class="swiper-slide" :style="{backgroundImage: 'url(https://files.magzter.com/' + favourite.imgPath + ')'}">
                    <span class="favourite-name-overlay white--text font-weight-bold active-slide-only">{{favourite.magName}}</span>
                    <span class="favourite-icon"
                          :class="{'active': isFavourite(favourite)}"
                          @click="$store.dispatch('favourites/toggleFavourite', favourite)"><i class="fas fa-heart"></i></span>
                </div>
            </div>
            <!-- Add Pagination -->
<!--            <div class="swiper-pagination"></div>-->
        </div>
        <div class="no-favourites white--text font-weight-bold px-6" v-else>
            <p >You have no favourites :-(</p>
            <p >Touch the heart <span class="sad-icon white--text"><i class="fas fa-heart"></i></span>
            on any magazine to add some!</p>
        </div>
    </section>
</template>

<script>
    import Vue from 'vue';
  import { mapState } from 'vuex';
  import Swiper from 'swiper/bundle';

  export default {
    props: {},
    components: {},
    computed: {
      ...mapState({
        favourites: state => state.favourites.favourites,
      })
    },
    watch: {
      favourites(){
        setTimeout(() => {
          this.setUpSlides();
        });
      }
    },
    data() {
      return {
        swiper: null,
      };
    },
    mounted() {
      Vue.nextTick(() => {
        this.setUpSlides();
      });
    },
    methods: {
      // getImgUrl: radioService.getImgUrl,
      setUpSlides(){
        if (this.favourites.length > 0) {
          this.swiper = new Swiper('.swiper-container-fav', {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            },
            pagination: {
              el: '.swiper-pagination',
            },
            // on: {
            //   click: function (swiper, event) {
            //     /* do something */
            //     console.log('clicked slide', swiper, 'e', event);
            //     swiper.slideTo(swiper.clickedIndex);
            //   },
            // }
          });

          setTimeout(() => {
            this.swiper.update();
          });
        }
      },
      isFavourite(mag){
        let favourite = false;

        this.favourites.forEach((item) => {
          if(item.magName === mag.magName) {
            favourite = true;
          }
        });

        return favourite;
      },
      emitFavourite(favourite, event){
        let clickedImage = event.target.classList.contains('swiper-slide');

        if(clickedImage) {
          this.$emit('result', favourite);
        }
      }
    },
  };
</script>
