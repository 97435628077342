import magazineService from '@/services/magazineService';

export default {
  addFavourite(mag, toggle = false){
    let favourites = this.getFavourites();

    if(!this.getFavouriteByName(mag.magName)) { // if favourite doesn't exist
      favourites.push(mag);
      this.setFavourites(favourites);
      magazineService.sendStat({mag: mag}, `Added magazine: ${mag.magName} to favourites`);
    } else if (toggle) {
      this.removeFavourite(mag);
      magazineService.sendStat({mag: mag}, `Removed magazine: ${mag.magName} from favourites`);
    }
  },
  removeFavourite(mag){
    let favourites = this.getFavourites();

    favourites = favourites.filter((item) => {
      return item.magName !== mag.magName;
    });

    this.setFavourites(favourites);
  },
  getFavourites(){
    let favourites = localStorage.getItem('magFavourites');

    if(favourites){
      favourites = JSON.parse(favourites);
    } else {
      favourites = [];
    }

    return favourites;
  },
  setFavourites(mags){
    localStorage.setItem('magFavourites',JSON.stringify(mags));
  },
  getFavouriteByName(name){
    let favourites = this.getFavourites();
    let selected = null;

    favourites.forEach((item) => {
      if(item.magName === name){
        selected = item;
      }
    });

    return selected;
  }
};
