<style lang="scss">
    .app-header{
        width: 100vw;

        &__name{
            color: $purple-dark;
            font-size: 14pt;
        }

        .favourites-icon{
            border-radius: 15px;
            width: 30px;
            height: 30px;
            background-color: $purple;

            i{
                color: white;
            }
        }

        .dark-mode-switch{
            i{
                color: black;
            }

            &.dark i{
                color: white;
            }

            .v-messages{
                display: none;
            }

            .v-input__slot{
                margin-bottom: 0;
            }
        }
    }
</style>

<template>
    <v-app-bar v-if="$route.name !== 'magazine-view'" class="app-toolbar app-header theme-background" fixed dense height="64px" max-height="64px">
        <img @click="$router.push({name: 'Home'})" width="60px" src="../assets/logo.png" alt="">
        <p class="app-header__name mb-0 ml-4 font-weight-bold theme-color-alt-only">Magazines</p>

        <v-btn v-if="isAndroid" @click="closeApp()" class="ml-6 btn-purple font-weight-bold btn-quit">
          Quit
        </v-btn>

        <v-spacer></v-spacer>

        <div class="mr-6 dark-mode-switch font-weight-bold" :class="{'dark': darkMode}">
            <v-switch
                    color="white"
                    v-model="darkMode"
                    inset
                    :label="darkMode ? 'Dark' : 'Light'"
            >
                <template slot="label">
                    <v-icon v-if="!darkMode">fas fa-sun</v-icon>
                    <v-icon v-if="darkMode">fas fa-moon</v-icon>
                </template>
            </v-switch>
        </div>

        <span v-if="$route.name === 'Home'"
                @click="scrollToFavourites()" class="favourites-icon d-flex justify-center align-center"><i class="fas fa-heart "></i></span>
    </v-app-bar>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'App-Header',
    props: [''],
    components: {},
    computed: {
      darkMode: {
        get () {
          return this.$store.state.darkMode
        },
        set (value) {
          this.$store.commit('SET_DARK_MODE', value)
        }
      },
      ...mapState({
        test: state => state.test,
        magazineToken: state => state.magazineToken,
      }),
      isAndroid() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1;
      }
    },
    data() {
      return {
        dark: false
      };
    },
    mounted() {

    },
    methods: {
      scrollToFavourites(){
        var favourites = document.querySelector('.favourites-section');

        if(favourites){
          favourites.scrollIntoView();
        }
      },
      closeApp() {
        // eslint-disable-next-line no-undef
        Android.quit();
      },
    },
  };
</script>
