<style lang="scss">
@include darkOnly() {
  .visual-search {
    border-bottom-color: $dark-blue-alt !important;
    border-top-color: $dark-blue-alt !important;

    &__languages{
      color: white !important;
      background-color: $dark-blue-alt;

      .v-select__selection, .v-icon {
        color: white;
      }

      .v-input__slot:before{
        border-color: white !important;
      }
    }

    .visual-search_stations img {
      box-shadow: -5px -5px 10px #000;
    }

    .visual-search__search {
      background-color: $dark-blue-alt !important;

      input, label {
        color: white !important;
      }

      input{
        &::placeholder {
          color: white !important;
        }
      }

      .v-icon{
        color: white !important;
      }
    }
  }
}

.visual-search {
  border-bottom: 20px solid white;
  border-top: 20px solid white;

  .visual-stations-wrapper {
    border-radius: 2em;
  }

  &__languages{
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 10px 20px 0 !important;
    max-width: 250px !important;
    margin-left: auto;
    font-weight: bolder !important;
  }

  .visual-search_stations {
    .visual-search-img {
      max-width: 150px;

      img {
        //border-radius: 20px;
        background: #e0e0e0;
        box-shadow:  5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
        transition: box-shadow 0.1s ease;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  &__genre {
    font-size: 1.5em;
  }

  .visual-search__search{
    height: 55px;
    border-radius: 1em;

    .v-input__slot{ // remove vuetify styling
      background: none !important;

      &::after, &::before{
        content: none !important;
      }

      .v-icon{
        color: $purple-dark;
        font-size: 2em;
      }
    }

    input, label {
      font-size: 20pt !important;
      font-weight: bold;
      text-indent: 10px;
      color: black !important;
    }

    label {
      height: 50px;
    }

    input{
      &::placeholder {
        color: black !important;
        opacity: 1; /* Firefox */
        font-weight: bold;
      }
    }
  }

}
</style>

<template>
  <section class="visual-search theme-purple-background">
    <div class="px-6 py-10">
      <v-select class="visual-search__languages mb-4"
          :items="languages"
          v-model="filters.lang">
      </v-select>

      <v-data-iterator
          :items="magazines"
          :search="filters.query"
          :items-per-page="30"
          hide-default-footer
          no-data-text=""
          no-results-text=""
      >
        <template v-slot:header>
          <v-toolbar
              color="white"
              class="mb-1 white--text visual-search__search"
          >
            <v-text-field
                v-model="filters.query"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search for a magazine"
            ></v-text-field>
          </v-toolbar>

          <section class="visual-search__genre d-flex justify-center align-center flex-wrap">
            <p :class="$mq === 'sm' ? 'py-1' : 'py-4'"
               class="white--text pa-2 mb-0 text-capitalize font-weight-bold pointer"
               @click="filters.query = genre"
               v-for="genre in genres" :key="genre">{{genre}}</p>
          </section>
        </template>
        <template v-slot:default="props">
          <div v-if="!loading" class="visual-stations-wrapper theme-white-grey px-4">
            <div class="visual-search_stations row pa-2">
              <section :class="$mq === 'sm' ? 'col-4' : 'col-2'"
                       class="col-2 d-flex justify-center align-center animate__animated animate__fadeIn" v-for="item in props.items" :key="item.name">
                <div class="visual-search-img pointer" @click="$emit('result', item)">
                  <img width="100%" height="auto" :src="'https://files.magzter.com/' + item.imgPath">
                </div>
              </section>
            </div>
          </div>
        </template>
        <template v-slot:no-results>
          <p :class="{'transparent': loading}" class="white--text font-weight-bold">No matching magazines found</p>
        </template>
      </v-data-iterator>
      <div v-if="loading" class="d-flex justify-center pb-6 pt-15">
        <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
      </div>
    </div>
  </section>
</template>

<script>
import magazineService from '@/services/magazineService';
import * as Languages from './languages.json';

export default {
  props: [''],
  components: {},
  data() {
    return {
      languages: Languages.languages,
      genres: [
          'film', 'gaming', 'entertainment', 'car', 'business',
        'travel', 'lifestyle', 'tech', 'celebrity', 'art', 'sport',
        'photography', 'science', 'food', 'bike', 'fishing'],
      magazines: [],
      loading: false,
      keys: [
        'name',
      ],
      timer: null,
      filters: {
        libID: '2060',
        page: 0,
        query: '',
        lang: 'english'
      }
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.searchForMagazines();
      }
    },
  },
  computed: {
    filteredKeys () {
      return this.keys.filter(key => key !== 'name')
    },
  },
  mounted() {
    // this.sortStations();
    this.searchForMagazines(false);
  },
  methods: {
    // sortStations() {
    //   this.stations.sort(function(a, b){
    //     if(a.name < b.name) { return -1; }
    //     if(a.name > b.name) { return 1; }
    //     return 0;
    //   })
    // },
    searchForMagazines(log = true) {
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.performSearch(log);
      }, 1000);
    },
    performSearch(log = true) {
      this.loading = true;

      if (log) {
        this.sendLog(`Searched for magazine with query: ${this.filters.query}`,
            {query: this.filters.query, lang: this.filters.lang});
      }

      magazineService.getAllMagazines(this.filters).then(async (res) => {
       // console.log('got magazines', res.data);
        this.magazines = await this.$store.dispatch('filterOutMagazines', res.data.hits);
      }).catch((err) => {
        this.$toasted.error('There is a problem with this magazine search, please search for another');
        console.log('could not get magazines', err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('sendLog', {
        description: description,
        meta: meta
      });
    },
  },
};
</script>
