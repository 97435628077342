<style lang="scss">
.magazine-hub {
  .issues-modal .v--modal-box {
    max-width: 100%;
  }

  .issues-modal .v--modal-background-click {
    background-color: rgba(0,0,0,0.6);
  }
}
</style>

<template>
    <section class="mb-10 magazine-hub">
        <query-magazines key="newspaper" title="Newspapers" query="Newspaper" @result="showIssues($event)"></query-magazines>
<!--        <query-magazines key="popular" title="Popular" query="Popular" @result="showIssues($event)"></query-magazines>-->
        <visual-search @result="showIssues($event)"></visual-search>
        <favourites @result="showIssues($event)"></favourites>

<!--        <modal name="issues" width="700px" height="auto">-->
        <modal @before-open="openedModal(true)"  @before-close="openedModal(false)" class="issues-modal" name="issues" height="auto">
            <issues @view="viewMagazine($event)" :mag="activeMag"></issues>
        </modal>
    </section>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { mapState } from 'vuex';
  import Favourites from './magSliders/Favourites';
  import QueryMagazines from './magSliders/QueryMagazines.vue';
  import Issues from './magSliders/Issues';
  import VisualSearch from '@/components/search/VisualSearch';

  export default {
    props: [''],
    components: {
      Favourites,
      QueryMagazines,
      Issues,
      VisualSearch,
    },
    computed: mapState({
      favourites: state => state.favourites.favourites,
    }),
    data() {
      return {
        activeMag: null,
      };
    },
    mounted() {
      this.$store.commit('favourites/SET_FAVOURITES_FROM_LOCAL');
    },
    methods: {
      showIssues(mag) {
        this.activeMag = mag;
        this.showModal('issues', true);
        this.sendLog(`Viewing issues for ${mag.magName}`, {
          magazine: mag.magName,
        });
      },
      showModal(name, value) {
        if(value){
          this.$modal.show(name);
        } else {
          this.$modal.hide(name);
        }
      },
      viewMagazine(issueId) {
        this.openedModal(false);
        this.$router.push({name: 'magazine-view', params: { issueId: issueId, magId: this.activeMag.objectID}});
      },
      sendLog(description, meta = null) {
        this.$store.dispatch('sendLog', {
          description: description,
          meta: meta
        });
      },
      openedModal(value = true) {
        document.querySelector('html').classList.toggle('no-scroll', value);
      }
    },
  };
</script>
