<style lang="scss">
  .home{

  }
</style>
<template>
  <div class="home">
    <magazine-hub></magazine-hub>
  </div>
</template>

<script>
import MagazineHub from '../components/MagazineHub';

export default {
  name: 'Home',
  components: {
    MagazineHub
  }
}
</script>
