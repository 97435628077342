<style lang="scss">
.magazine-view{
  min-height: 100vh;
  background: white;

  &__title-bar{
    font-size: 16pt;
    &__back{

    }

    &__magazine{
      &__name{
        text-transform: capitalize;
      }

      img{
        width: 115px;
        height: 100px;
        object-fit: contain;
      }
    }
  }

  &__mag{

    .iframe-wrapper {
      width: 100%;
      height: 80vh;
      position: relative;

      iframe{
        height: 80vh;
      }

      &__toggle{
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
        font-weight: bolder;
        border-bottom: 2px solid black;
      }

      &.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        iframe {
          height: 100vh;
        }
      }
    }

  }

  &__loader{
    background: linear-gradient(to right, $purple-alt 0%,#a849a3 83%);
    p{
      flex-basis: 100%;
      font-size: 35pt
    }
  }
}
</style>
<template>
  <div class="magazine-view theme-purple-background" :class="{'electron': isElectron}">
    <div v-if="!loading">
      <section>
<!--        <section :style="isAndroid ? 'padding-top: 80px' : ''" class="magazine-view__title-bar white&#45;&#45;text font-weight-bold d-flex align-center pa-4">-->
<!--          <p @click="$router.push({name: 'Home'})" class="magazine-view__title-bar__back mb-0 pointer">-->
<!--            <i class="fas fa-chevron-left mr-2"></i> Back to magazines</p>-->

<!--          <v-spacer></v-spacer>-->

<!--          <div v-if="magazine" class="magazine-view__title-bar__magazine d-flex align-center">-->
<!--            <p class="magazine-view__title-bar__magazine__name mr-4 mb-0">-->
<!--              {{magazine.magazineName}}-->
<!--            </p>-->
<!--            <img :src="'https://files.magzter.com/' + magazine.image" alt="magazine image">-->
<!--          </div>-->
<!--        </section>-->

        <section v-if="reader" class="magazine-view__mag">
          <div class="iframe-wrapper" :class="{'fullscreen': fullscreen}">
            <p class="iframe-wrapper__toggle" @click="$router.push({name: 'Home'})">Back to magazines</p>
            <iframe :src="reader" class="full-width" title="magzter.com magazines"></iframe>
          </div>

        </section>
        <section v-else>
          Magazine not found :-(
        </section>
      </section>
    </div>


    <section :style="isAndroid ? 'padding-top: 80px' : ''" class="magazine-view__loader d-flex justify-center pb-6 flex-wrap" v-else>
      <p class="font-weight-bold text-center mt-4 white--text">Getting your magazine...</p>
      <v-progress-circular :size="100" :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import magazineService from '../services/magazineService';
export default {
  name: 'Magazine-view',
  props: {},
  components: {

  },
  data() {
    return {
      magId: this.$route.params.magId,
      issueId: this.$route.params.issueId,
      loading: true,
      magazine: null,
      reader: null,
      fullscreen: false,
      // magazine: {
      //   url: 'https://reader.magzter.com/preview/sjdz2hzeq48qi3as6mwral5309470/530947'
      // }
    };
  },
  computed: {
    ...mapState({
      magazineToken: state => state.magazineToken,
      isAndroid: state => state.isAndroid,
      isElectron: state => state.isElectron,
      electronApiToken: state => state.electronApiToken,
    })
  },
  watch: {
    magazineToken: {
      deep: true,
      handler() {
        this.manageAndroidResponse();
      }
    }
  },
  mounted() {
    this.setUpMagazine();
    setTimeout(() => {
      this.loading = false;

      if(!this.magazineToken) { // if no token in 8 seconds, issue with magazine
        this.$toasted.error('There is a problem with this magazine, please choose another');
        this.$router.push({name: 'Home'});
      }
    }, 8000);
  },
  methods: {
    setUpMagazine() {
      this.getMagazineDetails();
      this.getMagazineToken();
    },
    getMagazineToken() {
      this.$store.commit('SET_MAGAZINE_TOKEN', null) // reset token
      if(this.isAndroid) {
        magazineService.generateReaderToken(this.magId, this.issueId);
      } else if(this.isElectron) {
        magazineService.generateReaderTokenElectron(this.magId, this.issueId);
      } else {
        magazineService.generateReaderTokenWeb(this.magId, this.issueId).then((res) => {
          const token = res.data.url;
          this.$store.commit('SET_MAGAZINE_TOKEN', token);
          // this.reader = `https://reader.magzter.com/reader/${token}/${this.issueId}`;
        }).catch(() => {
          this.$toasted.error('There is a problem with this magazine, please choose another');
          // this.$router.push({name: 'Home'});
        });
      }
    },
    manageAndroidResponse() {
      this.loading = false;
      const token = this.magazineToken;
      this.reader = `https://reader.magzter.com/reader/${token}/${this.issueId}`;
      this.fullscreen = true;
    },
    getMagazineDetails() {
      magazineService.getMagazine(this.magId).then((res) => {
        this.magazine = res.data;
        this.sendLog(`Reading magazine ${this.magazine.magazineName}, issue: ${this.magazine.latestIssueName || '-'}`, {
          magazine: this.magazine.magazineName,
          issue: this.magazine.latestIssueName || '-',
        });
      }).catch(() => {
        // this.$toasted.error('Could not get magazine details, please choose another');
      }).finally(() => {
        // this.loading = false;
      });
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('sendLog', {
        description: description,
        meta: meta
      });
    },
  },
}
</script>
