<style lang="scss">
    .latest-section{

        &__title{
            font-size: 20pt;
        }

        .no-favourites{
            font-size: 18pt;
        }
    }
</style>

<template>
    <section class="latest-section pb-4 theme-border-thick theme-purple-background">
       <div v-if="!loading" class="animate__animated animate__fadeIn animate__slow">
           <p class="latest-section__title white--text font-weight-bold px-6 py-4 mb-0">{{title}}</p>
           <div :class="sliderId" class="swiper-container swiper-container-popular" v-if="magazines.length > 0">
               <div class="swiper-wrapper">
                   <div v-for="mag in magazines" :key="mag.name"
                        @click="clickedMag($event, mag)"
                        class="swiper-slide" :style="{backgroundImage: 'url(https://files.magzter.com/' + mag.imgPath + ')'}">
                       <span class="favourite-name-overlay white--text font-weight-bold active-slide-only">{{mag.magName}}</span>
                       <span class="favourite-icon"
                             :class="{'active': isFavourite(mag)}"
                             @click="$store.dispatch('favourites/toggleFavourite', mag)"><i class="fas fa-heart"></i></span>
                   </div>
               </div>
               <!-- Add Pagination -->
               <!--            <div class="swiper-pagination"></div>-->
           </div>
           <div class="no-favourites white--text font-weight-bold px-6 py-10" v-else>
               <p>No Magazines found :-(</p>
           </div>
       </div>

        <div v-else class="d-flex justify-center pb-6 pt-15">
            <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
        </div>
    </section>
</template>

<script>
  import Vue from 'vue';
  import { mapState } from 'vuex';
  import Swiper from 'swiper/bundle';
  import magazineService from '../../services/magazineService';

  export default {
    props: {
      query: {
        required: true,
        type: String,
      },
      title: {
        required: true,
        type: String,
      }
    },
    components: {},
    computed: {
      ...mapState({
        favourites: state => state.favourites.favourites,
      })
    },
    data() {
      return {
        sliderId: '',
        loading: true,
        swiper: null,
        magazines: [],
      };
    },
    mounted() {
      this.sliderId = this.getRandomId(8);
     this.getMagazinesByQuery();
    },
    methods: {
      getMagazinesByQuery() {
        this.loading = true;
        magazineService.getMagazinesByQuery(this.query).then(async (res) => {
          this.magazines = await this.$store.dispatch('filterOutMagazines', res.data.hits);
        }).finally(() => {
          this.loading = false;

          Vue.nextTick(() => {
            this.setUpSlides();
          });
        });
      },
      setUpSlides(){
        this.swiper = new Swiper('.' + this.sliderId, {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          pagination: {
            el: '.swiper-pagination',
          },
          // on: {
          //   click: function (swiper, event) {
          //     /* do something */
          //     console.log('clicked slide', swiper, 'e', event);
          //     swiper.slideTo(swiper.clickedIndex);
          //   },
          // }
        });

        setTimeout(() => {
          this.swiper.update();
        });
      },
      clickedMag(e, mag) {
        if(!e.target.classList.contains('fa-heart')) {
          this.$emit('result', mag);
        }
      },
      getRandomId(length) {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP';
        let pass = '';
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < length; x++) {
          const i = Math.floor(Math.random() * chars.length);
          pass += chars.charAt(i);
        }

        return pass;
      },
      isFavourite(mag){
        let favourite = false;

        this.favourites.forEach((item) => {
          if(item.magName === mag.magName){
            favourite = true;
          }
        });

        return favourite;
      },
      emitFavourite(favourite, event){
        let clickedImage = event.target.classList.contains('swiper-slide');

        if(clickedImage) {
          this.$emit('play', favourite);
        }
      },
    },
  };
</script>
