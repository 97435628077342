<style lang="scss">
.issues-section-wrapper{
  .issues-section{
    height: 80vh;
    overflow-y: auto;

    &__title{
      font-size: 20pt;

      .favourite-icon{
        @include favouriteIcon();
      }
    }

    .no-favourites{
      font-size: 18pt;
    }
  }
}

</style>

<template>
  <div class="issues-section-wrapper pa-4 animate__animated animate__fadeIn animate__slow theme-background-blue">
    <section class="issues-section theme-purple-background">
      <div v-if="!loading">
        <p class="issues-section__title white--text font-weight-bold px-6 py-4 mb-0 d-flex justify-space-between">
          {{mag.magName}} Issues
          <span class="favourite-icon"
                :class="{'active': isFavourite(mag)}"
                @click="$store.dispatch('favourites/toggleFavourite', mag)"><i class="fas fa-heart"></i></span>
        </p>
        <div v-if="magazines.length > 0">
          <div class="swiper-container swiper-container-issues">
            <div class="swiper-wrapper">
              <div v-for="mag in magazines" :key="mag.editionId"
                   @click="$emit('view', mag.editionId)"
                   class="swiper-slide" :style="{backgroundImage: 'url(https://files.magzter.com/' + mag.editionImage + ')'}">
                <span class="favourite-name-overlay white--text font-weight-bold">{{mag.editionName}}</span>
              </div>
            </div>
          </div>

          <section class="white--text px-6 py-4 theme-border-thin">
            <h4 class="white--text">Edition Description</h4>
            <p>{{magazines[activeIndex].editionDescription.substr(0, 450)}}...</p>
          </section>
        </div>

        <div class="no-favourites white--text font-weight-bold px-6 py-10" v-else>
          <p>No Magazines found :-(</p>
        </div>
      </div>

      <div v-else class="d-flex justify-center pb-6 pt-15">
        <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="white"></v-progress-circular>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Vue from 'vue';
import Swiper from 'swiper/bundle';
import magazineService from '../../services/magazineService';

export default {
  props: {
    mag: {
      required: true,
      type: Object,
    }
  },
  components: {},
  computed: {
    ...mapState({
      favourites: state => state.favourites.favourites,
    })
  },
  data() {
    return {
      loading: true,
      swiper: null,
      magazines: [],
      activeIndex: 0,
    };
  },
  mounted() {
    this.getMagazineIssues();
  },
  methods: {
    // getImgUrl: radioService.getImgUrl,
    getMagazineIssues() {
      this.loading = true;
      magazineService.getMagazineIssues(this.mag.objectID).then((res) => {
        // console.log('got mag', res.data);
        this.magazines = res.data.edt_details;
      }).finally(() => {
        this.loading = false;

        Vue.nextTick(() => {
          this.setUpSlides();
        });
      });
    },
    setUpSlides(){
      this.swiper = new Swiper('.swiper-container-issues', {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      });

      this.swiper.on('slideChange', (swiper) => {
        this.activeIndex = swiper.activeIndex;
      });

      setTimeout(() => {
        this.swiper.update();
      });
    },
    isFavourite(mag){
      let favourite = false;

      this.favourites.forEach((item) => {
        if(item.magName === mag.magName){
          favourite = true;
        }
      });

      return favourite;
    },
    emitFavourite(favourite, event){
      let clickedImage = event.target.classList.contains('swiper-slide');

      if(clickedImage) {
        this.$emit('play', favourite);
      }
    }
  },
};
</script>
