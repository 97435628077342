import axios from 'axios';
import QueryStringBuilderService from './queryStringBuilderService';
import {REST_ENDPOINT} from '../../endpoints';
// import { MDM_ENDPOINT } from '@/util/endpoints';
const blockedKeywords = ['jail mail'];

const baseElectron ='https://purple-magazines.com';
const base = `${REST_ENDPOINT}/api/v1/`;

const apiClient = axios.create({
  baseURL: 'https://sls.magzter.com/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
});

const libId = '2060'; // purple id

export default {
  getAllMagazines(filters) {
    let queryString = QueryStringBuilderService.buildSearchQueryString(filters);
    return apiClient.get(`maglists/prod/magazine-libaray${queryString}`);
  },
  getMagazinesByQuery(query, language) {
    let queryString = QueryStringBuilderService.buildSearchQueryString({query: query, libID: libId, lang: language});
    return apiClient.get(`maglists/prod/magazine-libaray${queryString}`);
  },
  getImgUrl(imgPath, size = 'medium') {
    let url = `https://files.magzter.com/${imgPath}/view/`;

    if(size === 'small'){
      url += '1.jpg';
    } else if(size === 'medium') {
      url += '2.jpg';
    } else {
      url += '3.jpg';
    }

    return url;
  },
  getMagazineIssues(magazineId) {
    return apiClient.get(`magservices/prod/getIssuesByMag?libId=${libId}&mid=${magazineId}`);
  },
  getMagazine(magazineId) {
    return apiClient.get(`magservices/prod/magMeta?libId=${libId}&mid=${magazineId}`);
  },
  generateReaderToken(magId, issueId) {
    return new Promise(function (resolve, reject) {
      const ua = navigator.userAgent.toLowerCase();

      if(ua.indexOf("android") > -1) {
        // eslint-disable-next-line no-undef
        Android.getMagazine(magId, issueId);
        resolve('sent')
      }

      setTimeout(() => {
        reject('did not submit log');
      }, 10000);

      resolve('did not submit log');
    })
  },
  generateReaderTokenElectron(magId, issueId) {
    return axios.post(`${baseElectron}/electron-magazine`, { // fake request to send to electron. matches pattern
      magId: magId,
      issueId: issueId
    });
  },
  generateReaderTokenWeb(magId, issueId) { // for local testing, does not work live as needs valid token
    return axios.get(`http://localhost:3000/magazine?magId=${magId}&issueId=${issueId}&userId=2456`);
  },
  sendStat(meta, description) {
    const log = this.createMagazineLogObject(meta, description);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const ua = navigator.userAgent.toLowerCase();

      if(ua.indexOf("android") > -1) {
        // eslint-disable-next-line no-undef
        Android.sendActivityForLog(JSON.stringify(log));
        resolve('sent')
      }

      setTimeout(() => {
        reject('did not submit log');
      }, 10000);

      resolve('did not submit log');
    });
  },
  sendStatElectron(meta, description) { // fake request to send to electron. matches pattern
    return axios.post(`${baseElectron}/electron-log`, this.createMagazineLogObject(meta, description));
  },
  createMagazineLogObject(meta, description) {
    return {
      action: "Magazine Log",
      description: description,
      meta: JSON.stringify(meta),
      target: "com.madepurple.newsstand",
      app_id: 'com.madepurple.newsstand',
    };
  },
  convertMdmToken(token) {
    return axios.post(`${base}mdm-users/tokens`, { token })
  },
  filterOutMagazines(mags) {
    return mags.filter(mag => {
      let allowed = true;
      blockedKeywords.forEach(word => {
        if(mag.magName.toLowerCase().includes(word)) {
          allowed = false;
        }
      });
      return allowed;
    });
  }
};
