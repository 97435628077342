<template>
  <v-app class="light-theme">
    <app-header v-if="!$route.meta.headerHide"></app-header>
    <v-main class="theme-background">
      <v-container fluid class="no-gutters pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppHeader from './components/AppHeader.vue';
  import '@/assets/styles/styles.scss';
  import 'swiper/swiper-bundle.css'; // import Swiper styles

  export default {
    name: 'App',
    components: {
      AppHeader,
    },

    data: () => ({
      //
    }),
    mounted() {
      this.$store.dispatch('setIsAndroid');

      // listen for a custom android event
      window.addEventListener('androidMagazineSuccess', (data) => {
        if(data) {
          const url = JSON.parse(data.detail).url;
          this.$store.commit('SET_MAGAZINE_TOKEN', url);
        }
      });
    }
  };
</script>
